/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OX-hpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OVuhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXuhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUehpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXehpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOX-hpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOVuhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXuhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUehpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXehpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
:root{
  --mainColor: #880101;
  --bgColor: #ffffff;
  --sectionBg: #f8f8f8;
  --textColor: #212529;
}
body {
  background-color: var(--bgColor);
  color: var(--textColor);
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.red{
  color: var(--mainColor);
}
a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
.header{
  .header_top{
    background: #202327;
    color: #fff;
    text-align: center;
    padding: 10px 0px;
  }
  .header_middle{
    &::after{
      content: "";
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(5px);
    }
    .header_info{
      position: relative;
      z-index: 20;
    }
    position: relative;
    background: url('../assets/main_comp-min.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_info{
      text-align: center;
      a{
        color: #ffffff;
        .logo{
          .logo_img{
            background: url('../assets/news.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0px auto;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.front_section{
  &.section_bg{
    background-color: var(--sectionBg);
  }
  &.section_border{
    border-top: 1px solid #e8e8e8;
  }
}
.section_title{
  text-align: center;
}
h2.section_title{
  font-weight: bold;
}
.count_stat{
  .num{
    color: var(--mainColor);
    font-weight: 700;
  }
}
.metric_block{
  .item{
    .metrik_name{
      font-weight: bold;
      margin: 0px;
      text-align: right;
    }
    .metrik_graph{
      border-radius: 50%;
      background: black;
      width: 250px;
      height: 250px;
    }
  }
}
.metrik_list{
  padding: 0px;
  margin: 0px;
  li{
    list-style: none;
    position: relative;
    font-size: 18px;
    min-width: 240px;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    cursor: default;
    &::after{
      content: "";
      position: absolute;
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: black;
      top: 10px;
      left: 0;
    }
    &.li_red::after{
      background: #c63a04;
    }
    &.li_green::after{
      background: #9ac200;
    }
    &.li_orange::after{
      background: #de7d04;
    }
    &.li_yellow::after{
      background: #f2bd0d;
    }
    &.li_blue::after{
      background: #049ac0;
    }
    &.li_purple::after{
      background: #a74aa3;
    }
    &:not(:last-child){
      padding-bottom: 15px;
    }
    .val{

    }
    .sum{
      
    }
  }
}
p{
  &:last-child{
    margin-bottom: 0px;
  }
}
.article_info{
  label{
    color: var(--mainColor);
    font-weight: bold;
    margin-bottom: 20px;
  }
  p{
    &.p_padding{
      padding-left: 100px;
    }
  }
}
.chart_wrapper{
  &.active{
    path{
      opacity: 0.4!important;
    }
  }
  .rv-xy-plot__series{
    path{
      transition: transform .1s ease-in-out, opacity .1s ease-in-out;
      &.active{
        opacity: 1!important;
        position: relative;
        z-index: 5;
      }
    }
  }
}
.ico{
  display: inline-block;
  &.ico_desktop{
    background: url('../assets/icons.svg');
    background-position: 0px 0px;
  }
  &.ico_mobile{
    background: url('../assets/icons.svg');
    background-position: -45px 0px;
  }
  &.ico_location{
    background: url('../assets/contacts.svg');
    background-position: -79px -4px;
  }
  &.ico_mail{
    background: url('../assets/contacts.svg');
    background-position: -115px -4px;
  }
  &.ico_phone{
    background: url('../assets/contacts.svg');
    background-position: -41px -4px;
  }
  &.ico_wht{
    background: url('../assets/contacts.svg');
    background-position: -3px -4px;
  }
  &.ico_pattern{
    background-repeat: no-repeat;
  }
}
.full_image{
  background: url('../assets/full-min.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 550px;
}
.r_container{
  padding-top: 50px;
  padding-bottom: 30px;
  .r_label{
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      margin-right: 10px;
      background-size: 220px;
      width: 40px;
      height: 40px;
    }
  }
}
.col_dop{
  label{
    font-weight: bold;
  }
}
.var_info{
  h2{
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 26px;
  }
}
.footer{
  background: url('../assets/footer_comp.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  a{
    color: #ffffff;
    &:hover{
      opacity: 0.5;
    }
  }
  span{
    margin-right: 10px;
  }
  ul{
    padding: 0px;
    margin: 0px;
    li{
      list-style: none;
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 15px;
      }
      i.ico{
        margin-right: 10px;
        background-size: 150px;
      }
    }
  }
}
.image_fluid{
  max-width: 100%;
  height: auto;
}
.col_variant{
  padding-top: 30px;
  padding-bottom: 50px;
  .variant_type{
    text-align: center;
    position: relative;
    .varint_btn{
      cursor: pointer;
      margin-bottom: 20px;
      color: #a4a4a4;
      display: flex;
      align-items: center;
      justify-content: center;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      &:hover{
        span{
          color: var(--mainColor);
        }
      }
      i{
        width: 40px;
        height: 40px;
        background-size: 220px;
        margin-right: 15px;
        &.ico_mobile{
          background-position: -178px 0;
        }
        &.ico_desktop{
          background-position: -134px 0;
        }
      }
      .name_line{
        span{
          display: inline-block;
          border-bottom: 1px dashed;
          padding-bottom: 5px;
          padding-top: 5px;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    .varinat_info{
      .num{
        border: 3px solid #a4a4a4;
        border-radius: 50%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a4a4a4;
        margin: 0px auto;
      }
      .label{
        margin-top: 20px;
        color: #212529;
      }
    }
    &.active{
      .varint_btn{
        cursor: default;
      }
      .ico{
        &.ico_mobile{
          background-position: -45px 0;
        }
        &.ico_desktop{
          background-position: 0 0;
        }
      }
      .name_line{
        span{
          background: var(--mainColor);
          color: #ffffff;
          border: 0px;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      .varinat_info{
        .num{
          border-color: var(--mainColor);
          color: #000000;
        }
      }
    }
  }
}
.col_variant_img{
  text-align: center;
}
.var_picture .hide{
  display: none;
}
.col_v{
  position: relative;
}
.v_arr{
  width: 40px;
  height: 40px;
  background: url('../assets/icons.svg');
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: -130px -10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  margin-right: -20px;
}
@media (min-width: 994px) {
  .col_dop{
    padding-top: 20px;
  }
  .header{
    .header_middle{
      min-height: 500px;
    }
    .logo_img{
      width: 380px;
      height: 80px;
    }
    h2{
      font-size: 28px;
    }
    .header_top{
      font-size: 18px;
    }
  }
  .front_section{
    padding: 70px 0px;
  }
  .section_title{
    margin-bottom: 40px;
    &.title_margin{
      margin-bottom: 60px;
    }
  }
  h2.section_title{
    font-size: 30px;
  }
  .count_stat{
    text-align: center;
    .num{
      font-size: 34px;
    }
    .label{
      font-size: 18px;
    }
  }
  .metric_block{
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child){
      margin-bottom: 70px;
    }
    .item{
      padding-left: 30px;
      padding-right: 30px;
      .metrik_name{
        font-size: 18px;
        min-width: 75px;
      }
    }
  }
  .metric_desc{
    max-width: 1000px;
    margin: 0px auto;
  }
  .varinat_info{
    .num{
      width: 120px;
      height: 120px;
      font-size: 32px;
    }
  }
  .footer{
    padding: 40px 0px;
    h4{
      font-size: 22px;
      margin-bottom: 20px;
    }
    ul{
      li{
        i.ico{
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .pc_hid{
    display: none;
  }
}
@media (max-width: 993px) {
  .varint_img_desc{
    
  }
  .variant_h_section{
    overflow: hidden;
  }
  .main_mob_img{
    padding-top: 30px;
  }
  .r_container,
  .mobile_hid{
    display: none;
  }
  .footer{
    padding: 30px 0px;
    .col-lg-6{
      &:not(:last-child){
        margin-bottom: 30px;
      }
    }
    h4{
      font-size: 18px;
      margin-bottom: 20px;
    }
    ul{
      li{
        i.ico{
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .varinat_info{
    .num{
      width: 90px;
      height: 90px;
      font-size: 22px;
    }
    .label{
      font-size: 13px;
      max-width: 160px;
      margin: 0px auto;
    }
  }
  .full_image{
    display: none;
  }
  .metric_desc{
    max-width: 800px;
    margin: 0px auto;
    font-size: 14px;
  }
  .metric_block{
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .metrik_list{
      max-width: 370px;
      margin: 0px auto;
    }
    .item{
      &:first-child{
        -ms-flex: 0 0 30%;
        -webkit-box-flex: 0;
        flex: 0 0 30%;
        max-width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &:first-child + .item{
        -ms-flex: 0 0 70%;
        -webkit-box-flex: 0;
        flex: 0 0 70%;
        max-width: 70%;
      }
      &:last-child{
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 15px;
      }
      .metrik_name{
        font-size: 16px;
        padding-right: 30px;
      }
    }
  }
  .front_section{
    padding: 30px 0px;
  }
  .header{
    .header_top{
      font-size: 16px;
    }
    .header_middle{
      min-height: 270px;
    }
    .logo_img{
      width: 300px;
      height: 70px;
    }
    h2{
      font-size: 18px;
    }
  }
  .section_title{
    margin-bottom: 20px;
    &.title_margin{
      margin-bottom: 40px;
    }
  }
  h2.section_title{
    font-size: 20px;
  }
  .col-lg-4{
    &:not(:last-child){
      .count_stat{
        margin-bottom: 25px;
      }
    }
  }
  .count_stat{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 260px;
    margin: 0px auto;
    .num{
      font-size: 26px;
      min-width: 120px;
    }
    .label{
      font-size: 18px;
      margin-right: 20px;
      min-width: 80px;
    }
  }
  .col_variant .variant_type .varint_btn i{
    margin-right: 0px;
  }
  .col_variant .variant_type .varint_btn .name_line{
    display: none;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .full_image{
    min-height: 400px;
  }
}